@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Lato";
  font-weight: 400;
}

main {
  padding-top: 80px;
  flex: 1;
}

.reg-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
  }

  #intro {
    width: 100%;
    background-color: white;
    position: relative;
    padding: 0;
    margin-top: 0;
  }

  #about {
    /* height: 50vh; */
    background-color: rgb(216, 216, 216);
  }

  html, body, #root {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: darkgray;
    opacity: 0.5; /* Firefox */
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
}
  /* #intro:before {
    content: "";
    background: rgba(56, 64, 70, 0.7);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  
  #intro .container {
    z-index: 2;
  }
  
  #intro h1 {
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
  } */
/*   
  #intro h1 span {
    border-bottom: 4px solid #3498db;
  }
  
  #intro h2 {
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 30px;
    font-size: 24px;
  }
  
  #intro .btn-get-started {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 2px;
    display: inline-block;
    padding: 12px 28px;
    border-radius: 4px;
    transition: ease-in-out 0.3s;
    color: #fff;
    background: #3498db;
    text-transform: uppercase;
  }
  
  #intro .btn-get-started:hover {
    background: #4ea5e0;
  } */
  

.logo-small {
    display: none;
}

@media screen and (max-width: 1024px) {
    .logo-large {
        display: none;
    }
    .logo-small {
        display: inline;
    }
}

.navbar-nav {
    display: flex;
    justify-content: center; /* Center the items */
    width: 100%; /* Ensure it takes full width */
}
/* 
.navbar-nav .nav-link {
    text-align: center; 
} */

/* src/App.css */
/* .navbar-nav {
    display: flex;
    justify-content: center; /* Center the items */
    /* width: 100%; /* Ensure it takes full width */
/* } */

/* .navbar-nav .nav-link {
    text-align: end; 
}

.navbar-nav .nav-item {
    display: flex;
    justify-content: center;
    align-items: center; 
} */

.navbar-toggler {
    border: none; /* Remove border from the toggler button */
}

.navbar-toggler:focus {
    outline: none; /* Remove outline on focus */
}

.navbar-toggler-icon {
    border: none; /* Remove border from the icon */
}
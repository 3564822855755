/* src/Gallery.css */
.my-masonry-grid {
    display: flex;
    margin-left: -10px; /* gutter size offset */
    width: auto;
  }
  
  .my-masonry-grid_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;
  }
  
  .masonry-item {
    margin-bottom: 10px;
  }
  
  .masonry-item img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease;
  }
  
  .masonry-item:hover img {
    transform: scale(1.05);
  }
  